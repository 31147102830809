import React, { useRef, useEffect } from "react";
import Transition from "../Transition";

function ModalBasic({ children, id, title, open, setOpen, enClose, icon, noPadding }) {
  const modalContent = useRef(null);

  // close on click outside
  // useEffect(() => {
  //   const clickHandler = ({ target }) => {
  //     if (!open) return;
  //     setOpen(false);
  //   };
  //   document.addEventListener("click", clickHandler);
  //   return () => document.removeEventListener("click", clickHandler);
  // });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!open || keyCode !== 27) return;
      setOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 z-50 bg-gray-900 bg-opacity-30 transition-opacity"
        show={open}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 my-4 flex transform items-center justify-center overflow-hidden px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={open}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="max-h-full w-full max-w-3xl rounded-lg bg-white shadow-lg"
        >
          {/* Modal header */}
          <div className=" bg-[#FEEBE5] rounded-lg">
            <div className="flex items-center justify-between px-5 py-3">
              <div className="font-semibold text-black flex items-center gap-3">
                {icon}
                {title}
              </div>
              {enClose && (
                <button
                  className="text-monochrome-400 hover:text-monochrome-600 border bg-white border-transparent-black-10 p-1.5 rounded-lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(false);
                  }}
                >
                  <div className="sr-only">Close</div>
                  <svg className="h-4 w-4 fill-current">
                    <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                  </svg>
                </button>
              )}
            </div>
            <div className="bg-white rounded-t-lg h-3"></div>
          </div>
          <div className={`${!noPadding && "px-5 pb-3"} bg-white rounded-lg`}>{children}</div>
        </div>
      </Transition>
    </>
  );
}

export default ModalBasic;
