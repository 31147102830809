import React from "react";
import ModalBasic from ".";

const ModalInstruction = ({ open, setOpen, icon }) => {
  return (
    <ModalBasic
      name="tnc"
      open={open}
      setOpen={setOpen}
      title="Syarat & Ketentuan"
      enClose={true}
      icon={icon}
    >
      <div className="flex justify-center w-full">
        <img
          src="https://cdn.kar-tini.id/Frame_16_1_f0e1d19964.png"
          className="max-h-[75dvh]"
          alt="ABC Sambal Nusantara Instruction"
        />
      </div>
    </ModalBasic>
  );
};

export default ModalInstruction;
