import React from "react";
import ModalBasic from ".";
import AccordionComponent from "../Accordion";

const ModalFAQ = ({ open, setOpen, icon }) => {
  return (
    <ModalBasic
      name="tnc"
      open={open}
      setOpen={setOpen}
      title="Frequently Asked Questions"
      enClose={true}
      icon={icon}
      noPadding
    >
      <AccordionComponent />
    </ModalBasic>
  );
};

export default ModalFAQ;
