import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "../firebase/config";
import { logEvent } from "firebase/analytics";

const useAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: `${location.pathname}${location.search && location.search}`,
      firebase_screen_class: "firebase-route-analytics",
    });
  }, [location]);

  const logEventAnalytic = (eventName, eventParams) => {
    logEvent(analytics, eventName, eventParams);
  };
  return { logEventAnalytic };
};

export default useAnalytics;
